.header {
  height: 60px;
  border-bottom: 1px solid #dde0e4;

  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    width: 34px;
    height: 32px;
    border-radius: 4px;
    font-size: 15px !important;
  }

  .tree-title {
    font-family: "ClashDisplay-Medium";
    margin-left: 5px;
    max-width: 50vw;
    .MuiOutlinedInput-input {
      padding: 7px;
      min-width: 20vw;
      max-width: 40vw;
      outline: none;
    }
  }

  .MuiButton-startIcon {
    margin-right: 0px;
  }

  .basic-button {
    min-width: 28px;
    padding: 0px;
    justify-content: space-between;
    color: black;
    margin-left: 15px;
    padding-left: 5px;
    text-transform: capitalize;
    font-weight: 400;
  }

  .new-title {
    margin-left: 5px;
    margin-bottom: -2px;
  }

  .add-tree {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
