body {
  margin: 0;
  font-family: "ClashDisplay-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-boundary {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 40%;
}
