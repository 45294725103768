.line-container {
  /* align children in the center */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* a circle with 60x60 pixels */
  width: 100%;
  height: 2px;
  border-radius: 50%;
}

/* The button background layer */
.background {
  border-radius: 50%;
  background-image: #b3b3b3;
}

@keyframes moveInfinity {
  0% {
    left: 0;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 82%;
  }
}

.progress-container {
  width: 250px;
  max-width: 50vw;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "ClashDisplay-Medium";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-tree {
  position: absolute;
  top: 45%;
  right: 50%;
}

.progress-treehistory img {
  width: 250px;
  max-width: 50vw;
}
