.tree-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.tree-sidebar-inner {
  position: relative;
  padding: 0 16px 0 12px;
  overflow: hidden auto;
}

.title {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 28px;
  font-family: "ClashDisplay-Medium";
  padding: 12px 12px;
}

.sub-title {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 40px;
}

.tree-card {
  display: flex;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  gap: 10px;
  min-height: 60px;
  cursor: pointer;
  flex: 1;
}
.tree-card:hover {
  background: #f1f1f1;
  border: 1px solid #a9a9a9;
  .menuIcon {
    visibility: visible;
  }
}

.selected-card {
  border: 1px solid #3b75cd9c;
  background: #f1f1f1;
}

.selected-card:hover {
  border: 1px solid #3b75cd9c;
  background: #f1f1f1;
}

.tree-header {
  display: flex;
  align-items: center;
  height: 20px;
  font-family: "ClashDisplay-Medium";
  white-space: nowrap;
  display: inline-block;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-detail {
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px);
  height: auto;
  gap: 15px;
}

.tree-description {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 6rem;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #767676;
  line-height: normal;
}

.optionsContainer {
  position: relative;
  display: inline-block;
  height: 16px;
}

.menuIcon {
  border-radius: 6px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 3px 5px;
  align-items: flex-start;
  visibility: hidden;
}

.menuIcon:hover {
  background: rgb(250, 250, 250);
  border: 1px solid #cecece;
}

.dropdown-content {
  font-size: 12px;
  /* display: none; */
  position: absolute;
  right: 0;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
  padding: 10px 12px;
}

.dropdown-content .dropdown-item:hover {
  background-color: #f0f0f0;
}
