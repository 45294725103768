.plans-panel .plans-header {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 28px;
  font-family: "ClashDisplay-Medium";
  padding: 12px 0px;
  margin-left: -4px;
}

.plan-title-box {
  display: flex;
  margin-bottom: 10px;
}

.back-icon {
  color: #000;
}

.plans-panel {
  padding: 10px;
  padding-top: 0;
  position: relative;
  height: 100%;
  width: 100%;
}

.plan-key {
  color: #000;
  font-size: 16px;
  font-family: "ClashDisplay-Medium";
  line-height: normal;
  margin-bottom: 10px;
}

.plan-key-data {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.plan-title {
  color: #000;
  font-size: 20px;
  font-family: "ClashDisplay-Medium";
  margin-left: 5px;
}

.plan-section {
  overflow: auto;
  height: calc(100vh - 300px);
}

.card {
  padding: 10px;
  margin-bottom: 10px;
}

.copy-delete-button {
  background-color: #eeeeee !important;
  font-family: "ClashDisplay-Regular" !important;
  color: #585858 !important;
  text-transform: capitalize !important;
  margin: 5px !important;
  border-radius: 5px !important;
  padding: 3px 14px !important;
}
