.treeTypeOption {
  font-size: 12px !important;
  font-family: "ClashDisplay-Regular" !important;
  text-transform: none !important;
  border-radius: 8px !important;
  height: 100% !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 10px !important;
}

.upgradeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.upgrade {
  text-decoration: none;
  color: #1d77b7;
  cursor: pointer;
  font-size: 12px;
  font-family: "ClashDisplay-medium";
  display: none;
}